import React from "react"
import tw from "tailwind.macro"

const LeadText = ({ children, ...other }) => {
  return (
    <p css={tw`text-lg mb-4`} {...other}>
      {children}
    </p>
  )
}

export default LeadText
