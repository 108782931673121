const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  theme: {
    extend: {
      screens: {
        xs: {
          max: '639px',
        },
        sm: '540px',
        md: '720px',
        lg: '960px',
        xl: '1140px',
      },
      colors: {
        maroon: {
          default: '#60223b',
          '500': '#b0919d',
          '400': '#bfa7b1',
          '300': '#cfbdc4',
          '200': '#dfd3d8',
          '100': '#efe9eb',
          translucent: 'rgba(96,34,59,0.5)',
        },
        'dark-gray': {
          default: '#333333',
          '800': '#5c5c5c',
          '700': '#707070',
          '300': '#c2c2c2',
          '200': '#d6d6d6',
        },
        'light-gray': {
          default: '#8c979a',
          '100': '#f4f5f5',
        },
        gold: {
          default: '#d2b056',
        },
      },
      flex: {
        // Temporary fix for https://github.com/bradlc/babel-plugin-tailwind-components/issues/32
        grow: '1 0',
        shrink: '0 1',
      },
      fontFamily: {
        sans: 'Nunito',
        ...defaultTheme.fontFamily.sans,
      },
      container: {
        center: true,
        padding: '0.625rem',
      },
      inset: {
        '-2vh': '-2vh',
        '-5vh': '-5vh',
      },
      margin: {
        '-2vh': '-2vh',
        '-5vh': '-5vh',
      },
      maxHeight: {
        '0': '0',
        '2x-screen': '200vh',
      },
      transitionProperty: {
        'max-height': 'max-height',
      },
    },
  },
  plugins: [require('@tailwindcss/custom-forms')],
};
